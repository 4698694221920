var styles = {
  "Banner": "Polaris-Banner",
  "statusCritical": "Polaris-Banner--statusCritical",
  "PrimaryAction": "Polaris-Banner__PrimaryAction",
  "Button": "Polaris-Banner__Button",
  "statusWarning": "Polaris-Banner--statusWarning",
  "statusInfo": "Polaris-Banner--statusInfo",
  "statusSuccess": "Polaris-Banner--statusSuccess",
  "ContentWrapper": "Polaris-Banner__ContentWrapper",
  "withinContentContainer": "Polaris-Banner--withinContentContainer",
  "Dismiss": "Polaris-Banner__Dismiss",
  "Ribbon": "Polaris-Banner__Ribbon",
  "keyFocused": "Polaris-Banner--keyFocused",
  "Actions": "Polaris-Banner__Actions",
  "withinPage": "Polaris-Banner--withinPage",
  "hasDismiss": "Polaris-Banner--hasDismiss",
  "Heading": "Polaris-Banner__Heading",
  "Content": "Polaris-Banner__Content",
  "SecondaryAction": "Polaris-Banner__SecondaryAction",
  "Text": "Polaris-Banner__Text",
  "pressed": "Polaris-Banner--pressed",
  "loading": "Polaris-Banner--loading",
  "Spinner": "Polaris-Banner__Spinner"
};

export { styles as default };
