var styles = {
  "EmptyState": "Polaris-EmptyState",
  "Section": "Polaris-EmptyState__Section",
  "Details": "Polaris-EmptyState__Details",
  "Image": "Polaris-EmptyState__Image",
  "ImageContainer": "Polaris-EmptyState__ImageContainer",
  "DetailsContainer": "Polaris-EmptyState__DetailsContainer",
  "withinContentContainer": "Polaris-EmptyState--withinContentContainer",
  "Content": "Polaris-EmptyState__Content",
  "imageContained": "Polaris-EmptyState--imageContained",
  "fullWidth": "Polaris-EmptyState--fullWidth",
  "Actions": "Polaris-EmptyState__Actions",
  "FooterContent": "Polaris-EmptyState__FooterContent"
};

export { styles as default };
