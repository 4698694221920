var styles = {
  "TextField": "Polaris-TextField",
  "multiline": "Polaris-TextField--multiline",
  "Input": "Polaris-TextField__Input",
  "hasValue": "Polaris-TextField--hasValue",
  "focus": "Polaris-TextField--focus",
  "Backdrop": "Polaris-TextField__Backdrop",
  "error": "Polaris-TextField--error",
  "readOnly": "Polaris-TextField--readOnly",
  "disabled": "Polaris-TextField--disabled",
  "Prefix": "Polaris-TextField__Prefix",
  "Input-hasClearButton": "Polaris-TextField__Input--hasClearButton",
  "Input-suffixed": "Polaris-TextField__Input--suffixed",
  "Input-alignRight": "Polaris-TextField__Input--alignRight",
  "Input-alignLeft": "Polaris-TextField__Input--alignLeft",
  "Input-alignCenter": "Polaris-TextField__Input--alignCenter",
  "Suffix": "Polaris-TextField__Suffix",
  "CharacterCount": "Polaris-TextField__CharacterCount",
  "AlignFieldBottom": "Polaris-TextField__AlignFieldBottom",
  "ClearButton": "Polaris-TextField__ClearButton",
  "Hidden": "Polaris-TextField__Hidden",
  "Spinner": "Polaris-TextField__Spinner",
  "SpinnerIcon": "Polaris-TextField__SpinnerIcon",
  "Resizer": "Polaris-TextField__Resizer",
  "DummyInput": "Polaris-TextField__DummyInput",
  "Segment": "Polaris-TextField__Segment",
  "monospaced": "Polaris-TextField--monospaced"
};

export { styles as default };
